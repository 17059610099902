.DashboardPage :global {
    @apply w-full h-full;

    .logoSection {
        @apply text-left h-fit p-4;
        // @apply mt-[0.5vh] ml-[2.5vw];
        margin-top: 0.5vh;
        margin-left: 2.5vw;
    }

    .loginInfoSection {
        @apply lg:justify-end lg:text-right lg:px-10 lg:-mt-24 flex;
    }

    @media screen and (max-width: 767px) {
        .loginInfoSection {
            @apply w-full px-10 h-[200px] text-center flex-col justify-center;
        }

        .logoSection {
            @apply hidden;
        }
    }
}