@tailwind base;
@tailwind components;
@tailwind utilities;

/* Loader CSS Satrt */

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #615d5d81;
}

.loader {
  display: inline-block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;

  border: 10px solid #e3e3e3;
  border-top: 10px solid #231f20;
  border-radius: 50%;
  width: 90px;
  height: 90px;

  -webkit-animation: spin 1.5s infinite;
  animation: spin 1.5s infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Loader CSS End */
