.LoginPage :global {
    @apply w-full h-full;

    .logoSection {
        @apply text-left h-fit p-4;
        // @apply mt-[0.5vh] ml-[2.5vw];
        margin-top: 0.5vh;
        margin-left: 2.5vw;
    }

    .formSection {
        @apply lg:bg-white lg:border-grayLight lg:border-2 lg:max-w-[450px] lg:mx-auto lg:mt-5 lg:rounded-lg lg:shadow;
        @apply lg:p-5;
    }

    @media screen and (max-width: 767px) {
        .logoSection {
            @apply w-full px-10 h-[200px] mt-[10px] text-center flex flex-col justify-center;
        }

        .formSection {
            @apply mt-[70px] px-10 py-5 ml-0;
        }
    }
}