.Layout :global {
    @apply relative min-w-[98vw] min-h-[100vh] bg-white max-w-[100vw] overflow-hidden;

    .mainBackground {
        // @apply absolute shadow -top-[575px] -left-[109%] h-[800px] w-[calc(100vw+600px)];
        @apply absolute shadow h-[40vh] w-[30vw] -top-[20vh] -left-[10vw];
        @apply rounded-b-[100%] border-grayLight border-[10px];
        @apply bg-gradient-to-r from-primary-1 to-primary-2;
    }

    .mainContent {
        @apply relative z-10;
    }

    @media screen and (max-width: 767px) {
        .mainBackground {
            @apply absolute shadow -top-[560px] -left-[300px] h-[800px] w-[calc(100vw+600px)];
            @apply rounded-b-[100%] border-grayLight border-[10px];
            @apply bg-gradient-to-r from-primary-1 to-primary-2;
        }
    }
}